<template>
  <v-main class="container-prosker">
    <v-data-table
        calculate-widths
        :headers="headers"
        :footer-props="{itemsPerPageText: $t('rowsPerPage')}"
        :items="subscriptionsList"
        class="elevation-1 mt-5"
        item-key="id"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{ $t('orders') }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item.created_at="{ item }">
          <span>{{ getDateFormat(item.created_at) }}</span>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip class="white--text" :color="getColorClass(item.status)">
            {{ getStatusName(item.status) }}
          </v-chip>
        </template>
        <template v-slot:item.subscription.cost="{ item }">
          $ {{ item.subscription.cost }}
        </template>
        <template v-slot:no-data>
          <v-alert
            :value="true"
            color="error"
            icon="warning"
            class="white--text"
          >
            <span class="white--text">
              {{ $t("noData") }}
            </span>
          </v-alert>
        </template>
      </v-data-table>
  </v-main>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'UserOrders',
  data () {
    return {
      subscriptionsList: []
    };
  },
  mounted () {
    this.list();
  },
  computed: {
    headers () {
          return [
              { text: this.$t('subscription'), value: 'subscription.name' },
              { text: this.$t('startDate'), value: 'created_at' },
              { text: this.$t('period'), value: 'period' },
              { text: this.$t('status'), value: 'status' },
              { text: this.$t('price'), value: 'subscription.cost' }
          ];
      },
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  methods: {
    getDateFormat (date) {
      const originalDate = new Date(date);
      return moment(moment.utc(originalDate)).format('DD-MM-YYYY');
    },
    getStatusName (status) {
      if (status === 10) {
        return this.$t('pendingPayment');
      } else if (status === 20) {
        return this.$t('paid');
      }
    },
    getColorClass (status) {
      if (status === 10) {
        return 'warning';
      } else if (status === 20) {
        return '#7EBE72';
      }
    },
    async list () {
      if (!this.currentUser) {
        return;
      }

      const url = `${process.env.VUE_APP_BASE_URL}/api/orders?filters=user_id=${this.currentUser.id}`;
      await axios.get(url).then(({ data }) => {
        this.subscriptionsList = data.data;
      });
    }
  }
};
</script>
